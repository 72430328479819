import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miller-dpi',
  templateUrl: './miller-dpi.component.html',
  styleUrls: ['./miller-dpi.component.css']
})
export class MillerDpiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  

}
