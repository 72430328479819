import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-raccorderia-e-accessori',
  templateUrl: './raccorderia-e-accessori.component.html',
  styleUrls: ['./raccorderia-e-accessori.component.css']
})
export class RaccorderiaEAccessoriComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) {
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/manometri-a-sacco-e-rondelle-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/manometri-a-sacco-e-rondelle-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/filtri-e-ricambi-alta-e-bassa-pressione-olio-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/filtri-e-ricambi-alta-e-bassa-pressione-olio-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/innesti-rapidi-a-sfera-valvole-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/innesti-rapidi-a-sfera-valvole-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/raccorderia-alta-pressione-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/raccorderia-alta-pressione-siras-oleodinamica.jpg'})
   }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }
 

}
