import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-pneumatica',
  templateUrl: './pneumatica.component.html',
  styleUrls: ['./pneumatica.component.css']
})
export class PneumaticaComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) { 
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pneumatica-valvole-a-comando-meccanico-e-manuale-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pneumatica-valvole-a-comando-meccanico-e-manuale-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pneumatica-elettrovalvole-e-valvole-a-comando-pneumatico-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pneumatica-elettrovalvole-e-valvole-a-comando-pneumatico-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pneumatica-trattamento-aria-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pneumatica-trattamento-aria-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pneumatica-cilindri-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pneumatica-cilindri-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pneumatica-raccordi-e-accessori-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pneumatica-raccordi-e-accessori-siras-oleodinamica.jpg'})
  }

  ngOnInit(): void {
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

}
