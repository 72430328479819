import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-tubi-flessibili',
  templateUrl: './tubi-flessibili.component.html',
  styleUrls: ['./tubi-flessibili.component.css']
})
export class TubiFlessibiliComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) {
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/raccordi-boccole-a-pressare-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/raccordi-boccole-a-pressare-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/tubazioni-flessibili-alta-bassa-pressione-olii-carburanti-acqua-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/tubazioni-flessibili-alta-bassa-pressione-olii-carburanti-acqua-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/tubazioni-flessibili-raccordate-tubi-freni-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/tubazioni-flessibili-raccordate-tubi-freni-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/tubo-in-fe-bonderizzato-circuiti-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/tubo-in-fe-bonderizzato-circuiti-siras-oleodinamica.jpg'})
   }

   open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }
 

  ngOnInit(): void {
  }

}
