import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-cilindri-oleodinamici',
  templateUrl: './cilindri-oleodinamici.component.html',
  styleUrls: ['./cilindri-oleodinamici.component.css']
})
export class CilindriOleodinamiciComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) { 
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-saldati-a-doppio-e-semplice-effetto-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-saldati-a-doppio-e-semplice-effetto-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/guarnizioni-ricambio-vendita-montaggio-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/guarnizioni-ricambio-vendita-montaggio-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-prodotti-in-piccole-medie-grandi-serie-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-prodotti-in-piccole-medie-grandi-serie-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-speciali-a-disegno-a-norme-ISO-a-tiranti-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/cilindri-oleodinamici-speciali-a-disegno-a-norme-ISO-a-tiranti-siras-oleodinamica.jpg'})
  }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }
}
