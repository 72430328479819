<nav class="navbar navbar-expand-lg navbar-light bg-white mb-3 mt-3  sticky-top">
 
  <button class="navbar-toggler text-warning border-light m-3" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>
  <div [ngbCollapse]="isMenuCollapsed"  class="collapse navbar-collapse mx-5" style="justify-content: center;">
    <ul class="navbar-nav ">
      <li class="nav-item mx-3">
        <a class="nav-link text-siras titolo text-uppercase" (click)="isMenuCollapsed = true" [routerLink]="'/'" > Home </a>
      </li>
     
      <li class="nav-item  mx-3" ngbDropdown>
        <a ngbDropdownToggle class="nav-link  dropdown-toggle thumbnail text-siras titolo text-uppercase" type="button" id="dropdownMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Prodotti
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu ">
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/centrali-oleodinamiche'" class="lead border border-warning my-2" >Centrali oleodinamiche</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/componenti'" class="lead border border-warning my-2" >Componenti</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/raccorderia-e-accessori'" class="lead border border-warning my-2" >Raccorderia e accessori</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/pneumatica'" class="lead border border-warning my-2" >Pneumatica</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/tubi-flessibili'" class="lead border border-warning my-2" >Tubi flessibili</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/cilindri-oleodinamici'" class="lead border border-warning my-2" >Cilindri oleodinamici</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/lavorazioni-meccaniche'" class="lead border border-warning my-2" >Lavorazioni meccaniche</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/attrezzature-da-officina'" class="lead border border-warning my-2" >Attrezzature da officina</a>
          <a ngbDropdownItem (click)="isMenuCollapsed = true" [routerLink]="'/prodotti/accessori-per-agricolture'" class="lead border border-warning my-2" >Accessori per agricoltura</a>
        </div>
      </li>
      <li class="nav-item  mx-3">
        <a class="nav-link text-siras titolo text-uppercase" (click)="isMenuCollapsed = true" href="https://www.oleodinamicaflex.it" target="_blank"> Shop Online </a>
      </li>
      <li class="nav-item  mx-3">
        <a class="nav-link text-siras titolo text-uppercase" (click)="isMenuCollapsed = true" [routerLink]="'/servizi'"> Servizi </a>
      </li>
      <li class="nav-item  mx-3">
        <a class="nav-link text-siras titolo text-uppercase" (click)="isMenuCollapsed = true" [routerLink]="'/chi-siamo'" > Dove Siamo </a>
      </li>

      <li class="nav-item  mx-3">
        <a class="nav-link text-siras titolo text-uppercase" (click)="isMenuCollapsed = true" [routerLink]="'/contatti'" >Contatti</a>
      </li>
    </ul>
  </div>
</nav>
