import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-prova',
  templateUrl: './prova.component.html',
  styleUrls: ['./prova.component.css']
})
export class ProvaComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) { 
    this.album.push({'src':'https://www.siras.blu-system-sites.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica.jpg','caption':'Imag1','thumb':'https://www.siras.blu-system-sites.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica.jpg'})
  }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
