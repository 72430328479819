import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-errore404',
  templateUrl: './errore404.component.html',
  styleUrls: ['./errore404.component.css']
})
export class Errore404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
