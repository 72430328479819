import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-accessori-per-agricoltura',
  templateUrl: './accessori-per-agricoltura.component.html',
  styleUrls: ['./accessori-per-agricoltura.component.css']
})
export class AccessoriPerAgricolturaComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) {
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/terzi-punti-meccanici-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/terzi-punti-meccanici-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/accessori-carro-botte-cilindri-telescopici-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/accessori-carro-botte-cilindri-telescopici-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/cuscinetti-cinghie-supporti-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/cuscinetti-cinghie-supporti-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pompe-di-travaso-elettriche-e-manuali-crik-a-bottiglia-e-a-carrello-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pompe-di-travaso-elettriche-e-manuali-crik-a-bottiglia-e-a-carrello-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/fanaleria-pannelli-segnaletici-pompe-di-grassaggio-manuali-e-pneumatiche-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/fanaleria-pannelli-segnaletici-pompe-di-grassaggio-manuali-e-pneumatiche-siras-oleodinamica.jpg'})
   }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

}
