import { FunzioniLayout } from './shared/FunzioniLayout';
import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter} from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'siras';
  isShow: boolean;
  topPosToStartShowing = 100;


  
  //Serve per gestire la freccia che riporta in su la pagina
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

  }
  
  constructor(private funzioniLayout:FunzioniLayout, private router:Router){
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-N1HZ024D6M', { 'page_path': event.urlAfterRedirects });
      this.funzioniLayout.gotoTopPage();
    })

    
  }

  gotoTop(){
    this.funzioniLayout.gotoTopPage();
  }
}
