import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-attrezzature-da-officina',
  templateUrl: './attrezzature-da-officina.component.html',
  styleUrls: ['./attrezzature-da-officina.component.css']
})
export class AttrezzatureDaOfficinaComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) { 
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/attrezzatura-da-officina-kit-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/attrezzatura-da-officina-kit-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/antinfortunistica-prodotti-lubrificazione-e-isolanti-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/antinfortunistica-prodotti-lubrificazione-e-isolanti-siras-oleodinamica.jpg'})
  }

  ngOnInit(): void {
  }

  
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

}
