import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';


@Component({
  selector: 'app-centrali-oleodinamiche',
  templateUrl: './centrali-oleodinamiche.component.html',
  styleUrls: ['./centrali-oleodinamiche.component.css']
})
export class CentraliOleodinamicheComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) {
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/assemblaggi-speciali-su-specifiche-del-cliente--siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/assemblaggi-speciali-su-specifiche-del-cliente--siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/centrali-motori-da-05-a-30-hp-serbatoi-da-10-a-1000-litri-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/centrali-motori-da-05-a-30-hp-serbatoi-da-10-a-1000-litri-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/minicentraline-motori-corrente-continua-monofase-trifase-siras-oleodinamica.jpg'})
   }
  
  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }
 

}
