import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-componenti',
  templateUrl: './componenti.component.html',
  styleUrls: ['./componenti.component.css']
})
export class ComponentiComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) {
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/distributori-monoblocco-deviatori-di-flusso-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/distributori-monoblocco-deviatori-di-flusso-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/pompe-volumetriche-ad-ingranaggi-industriali-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/pompe-volumetriche-ad-ingranaggi-industriali-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/valvole-di-blocco-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/valvole-di-blocco-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/scambiatori-di-calore-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/scambiatori-di-calore-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/moltiplicatori-riduttori-giunti-elastici-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/moltiplicatori-riduttori-giunti-elastici-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/elettrovalvole-e-componenti-modulari-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/elettrovalvole-e-componenti-modulari-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/manifold-ghisa-e-alluminio-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/manifold-ghisa-e-alluminio-siras-oleodinamica.jpg'})
   }

  ngOnInit(): void {
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

}
