
<section class="container text-center mt-5">
    <img class="img-fluid w-250" src="/assets/img/siras-oleodinamica-logo.png">
</section>
<div class="m-base pb-5">
<div class="bg-white radius-min mt-5  bordo-3d ">
<div class="row text-center pt-2" style="justify-content: center;">
  <div class="col-lg-4">
    <p class="font-siras text-left px-2 mr-3 text-white bg-siras titolo text-uppercase py-2 pl-4">Prodotti</p>
    <ul class="text-left lista">
        <li><a [routerLink]="'/prodotti/centrali-oleodinamiche'" class="lead text-siras">Centrali oleodinamiche</a></li>
        <li><a [routerLink]="'/prodotti/componenti'" class="lead text-siras">Componenti</a></li>
        <li><a [routerLink]="'/prodotti/raccorderia-e-accessori'" class="lead text-siras">Raccorderia e accessori</a></li>
        <li><a [routerLink]="'/prodotti/pneumatica'" class="lead text-siras">Pneumatica</a></li>
        <li><a [routerLink]="'/prodotti/tubi-flessibili'" class="lead text-siras">Tubi flessibili</a></li>
        <li><a [routerLink]="'/prodotti/cilindri-oleodinamici'" class="lead text-siras">Cilindri oleodinamici</a></li>
        <li><a [routerLink]="'/prodotti/lavorazioni-meccaniche'" class="lead text-siras">Lavorazioni meccaniche</a></li>
        <li><a [routerLink]="'/prodotti/attrezzature-da-officina'" class="lead text-siras">Attrezzatura da officina</a></li>
        <li><a [routerLink]="'/prodotti/accessori-per-agricolture'" class="lead text-siras">Accessori per agricoltura</a></li>


    </ul>
  </div>
  <div class="col-lg-8 ">
    <p class="font-siras text-left px-2 ml-3 text-white bg-siras titolo text-uppercase py-2 pr-4">Vendita</p>
   <p class="lead p-3 text-left">Sempre disponibile una vasta gamma di componenti ed accessori vari :
</p>
<ul class="lead p-3 text-left ">
    <li>Accessori e ricambi agricoli per sollevamento, travaso olii e carburanti, grassaggio, irrigazione-spurghi, cuscinetti a sfere, cinghie di trasmissione, su<br><br></li>
    <li>Raccordi adattatori o a pressare GAS, METRICI, DIN, NPT, JIC, ORFS.<br><br></li>
    <li>Tubi flessibili in spezzoni, alta o bassa pressione per olii minerali, aria, acqua, gas, carburanti vari, vapore, vernici. <br><br></li>
    <li>Tubi circuiti in acciaio zincato o grezzo, sagomabili, Collari fissa-tubo in polipropilene.<br><br></li>
    <li>Pompe oleodinamiche a cilindrata costante o variabile.<br><br></li>
    <li>Motori idraulici ad ingranaggi ed orbitali.<br><br></li>
    <li>Valvole oleo-meccaniche di blocco e controllo movimento , montaggio in linea o modulare.<br><br></li>
    <li>Elettrovalvole e componenti modulari CETOP ( proporzionali su richiesta ).<br><br></li>
    <li>Accessori per accoppiamenti motori-pompe, misurazione pressioni e temperature olio, indicatori di livello.<br><br></li>
    <li>Componenti pneumatici : tubi termoplastici, raccordi rapidi , trattamento aria, valvole ed elettrovalvole, cilindri d accessori.<br><br></li>
    <li> uarnizioni per oleodinamica e pneumatica.<br><br></li>
    <li>Componenti per assemblaggio cilindri oleodinamici.<br><br></li>
    <li>Barre cromate, tubi in Fe, lappati H8 o levigati H9, tubi cromati esternamente.<br><br></li>
    <li>Attrezzature da officina , antinfortunistica.<br><br></li>

</ul>
<p class="lead p-3 text-left"> Un’accogliente area espositiva con ingresso e banco è adibita al ricevimento dei clienti e delle loro richieste.
</p>
  </div>
</div>

<div class="bg-siras row m-0">
    <div class="col-lg-6 mt-5">
    <img class="img-fluid w-20 p-3" src="/assets/img/siras-oleodinamica-logo-2.png">
    </div>
    <div class="col-lg-6">
        <p class="lead text-white text-right p-3" style="font-size: 17px;">
            Siras S.a.S - S.S. 35 Milano-Genova - Via Turati, 16<br>
San Martino Siccomario (PV) P.IVA: 00211280185<br>
<a href="https://www.iubenda.com/privacy-policy/39661139" target="_blank" class=" text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<a href="https://www.iubenda.com/privacy-policy/39661139/cookie-policy"  target="_blank" class="text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe m-4" title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<a href="https://www.blu-system.com/" class="text-white" target="_blank">Powered by Blu System</a>
        </p>
    </div>
</div>
</div>
</div>


