import { ProvaComponent } from './prova/prova.component';
import { AccessoriPerAgricolturaComponent } from './page/prodotti/accessori-per-agricoltura/accessori-per-agricoltura.component';
import { AttrezzatureDaOfficinaComponent } from './page/prodotti/attrezzature-da-officina/attrezzature-da-officina.component';
import { LavorazioniMeccanicheComponent } from './page/prodotti/lavorazioni-meccaniche/lavorazioni-meccaniche.component';
import { CilindriOleodinamiciComponent } from './page/prodotti/cilindri-oleodinamici/cilindri-oleodinamici.component';
import { TubiFlessibiliComponent } from './page/prodotti/tubi-flessibili/tubi-flessibili.component';
import { PneumaticaComponent } from './page/prodotti/pneumatica/pneumatica.component';
import { RaccorderiaEAccessoriComponent } from './page/prodotti/raccorderia-e-accessori/raccorderia-e-accessori.component';
import { ComponentiComponent } from './page/prodotti/componenti/componenti.component';
import { CentraliOleodinamicheComponent } from './page/prodotti/centrali-oleodinamiche/centrali-oleodinamiche.component';
import { MillerDpiComponent } from './page/miller-dpi/miller-dpi.component';
import { VenditaComponent } from './page/vendita/vendita.component';
import { RiparazioniComponent } from './page/riparazioni/riparazioni.component';
import { AssemblaggiComponent } from './page/assemblaggi/assemblaggi.component';
import { ProgettazioneComponent } from './page/progettazione/progettazione.component';
import { ServiziComponent } from './page/servizi/servizi.component';
import { ContattiComponent } from './page/contatti/contatti.component';
import { ChiSiamoComponent } from './page/chi-siamo/chi-siamo.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Errore404Component } from './page/errore404/errore404.component';

const routes: Routes = [
  {path:'', component:HomepageComponent},
  {path:'chi-siamo', component:ChiSiamoComponent},
  {path:'contatti', component:ContattiComponent},
  {path:'servizi', component:ServiziComponent},
  {path:'progettazione', component:ProgettazioneComponent},
  {path:'assemblaggi', component:AssemblaggiComponent},
  {path:'riparazioni', component:RiparazioniComponent},
  {path:'vendita', component:VenditaComponent},
  {path:'miller-dpi', component:MillerDpiComponent},
  {path:'prodotti/centrali-oleodinamiche', component:CentraliOleodinamicheComponent},
  {path:'prodotti/componenti', component:ComponentiComponent},
  {path:'prodotti/raccorderia-e-accessori', component:RaccorderiaEAccessoriComponent},
  {path:'prodotti/pneumatica', component:PneumaticaComponent},
  {path:'prodotti/tubi-flessibili',component:TubiFlessibiliComponent},
  {path:'prodotti/cilindri-oleodinamici', component:CilindriOleodinamiciComponent},
  {path:'prodotti/lavorazioni-meccaniche', component:LavorazioniMeccanicheComponent},
  {path:'prodotti/attrezzature-da-officina', component:AttrezzatureDaOfficinaComponent},
  {path:'prodotti/accessori-per-agricolture', component:AccessoriPerAgricolturaComponent},
  {path:'prova', component:ProvaComponent},
  {path:'**', component:Errore404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
