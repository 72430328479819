<div class="text-center bg-orange row mat-elevation-z20 d-none d-lg-flex py-3 m-0">
	<div class="col-lg-4">
		<a class="d-block text-white  font-size-18 "  title="molino lucconi orari"><span class="material-icons">
            query_builder
            </span>
		Lun-Ven 8.30-12.30/14.00-18.00  Sab 8.30-12.00 
		</a>
	</div>
	<div class="col-lg-2">
		<a class="d-block text-white  font-size-18 "  title="molino lucconi orari"><span class="material-icons">
            call
            </span>
			0382 55.96.28
		</a>
	</div>
	<div class="col-lg-3">
		<a class="d-block text-white  font-size-18 "  title="molino lucconi orari"><span class="material-icons">
            location_on
            </span>
			Via F. Turati, 16, 27028 San Martino Siccomario PV
		</a>
	</div>
	<div class="col-lg-3">
		<a class="d-block text-white  font-size-18 "  title="molino lucconi orari"><span class="material-icons">
            email
            </span>
			info@siras.it
		</a>
	</div>
</div>