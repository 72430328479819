import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-img',
  templateUrl: './dialog-img.component.html',
  styleUrls: ['./dialog-img.component.css']
})
export class DialogImgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
