<div class="text-center bg-orange row mat-elevation-z20  py-3 m-0">
	<div class="col-lg-4 bg-siras p-5">
        <a class="navbar-brand" [routerLink]="'/'">
            <img class="img-fluid w-75 mt-5 " src="/assets/img/siras-oleodinamica-logo.png">
          </a>
	</div>
	<div class="col-lg-4 py-5 text-center">
        <p class="lead text-white">Link utili</p>
        <hr>
		<ul class=" text-white  font-size-18 list-unstyled text-center "  title="Contattaci">
			<li class="py-2"> <a class="text-white lead" [routerLink]="'/contatti'" >Contattaci</a></li>
            <li class="py-2"> <a class="text-white lead" [routerLink]="'/servizi'">Servizi</a></li>
            <li class="py-2"> <a class="text-white lead" [routerLink]="'/progettazione'">Progettazione</a></li>
            <li class="py-2"> <a class="text-white lead" [routerLink]="'/prodotti/centrali-oleodinamiche'"  >Centrali oleodinamiche</a></li>
            <li class="py-2"> <a class="text-white lead" [routerLink]="'/riparazioni'">Riparazioni</a></li>
        </ul>
	</div>
    <div class="col-lg-3 py-5 text-center">
        <p class="lead text-white">Informazioni aziendali</p>
        <hr>
        <p class="lead text-white">Siras S.a.S - S.S. 35 Milano-Genova - Via Turati, 16San Martino Siccomario (PV)</p>
        <p class="lead text-white">P.IVA: 00211280185</p>
        <p class="lead text-white">info@siras.it</p>
        <p class="lead text-white">PEC: sirasferrari@pecsicura.it</p>
        <p class="lead text-white">Codice destinatario: BLOPIT22697</p>
	</div>
	
</div>
