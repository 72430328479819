import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TopbarComponent } from './component/topbar/topbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomepageComponent } from './page/homepage/homepage.component';
import { ChiSiamoComponent } from './page/chi-siamo/chi-siamo.component';
import { ContattiComponent } from './page/contatti/contatti.component';
import { ServiziComponent } from './page/servizi/servizi.component';
import { ProgettazioneComponent } from './page/progettazione/progettazione.component';
import { AssemblaggiComponent } from './page/assemblaggi/assemblaggi.component';
import { RiparazioniComponent } from './page/riparazioni/riparazioni.component';
import { VenditaComponent } from './page/vendita/vendita.component';
import { MillerDpiComponent } from './page/miller-dpi/miller-dpi.component';
import { CentraliOleodinamicheComponent } from './page/prodotti/centrali-oleodinamiche/centrali-oleodinamiche.component';
import { ComponentiComponent } from './page/prodotti/componenti/componenti.component';
import { RaccorderiaEAccessoriComponent } from './page/prodotti/raccorderia-e-accessori/raccorderia-e-accessori.component';
import { PneumaticaComponent } from './page/prodotti/pneumatica/pneumatica.component';
import { TubiFlessibiliComponent } from './page/prodotti/tubi-flessibili/tubi-flessibili.component';
import { CilindriOleodinamiciComponent } from './page/prodotti/cilindri-oleodinamici/cilindri-oleodinamici.component';
import { LavorazioniMeccanicheComponent } from './page/prodotti/lavorazioni-meccaniche/lavorazioni-meccaniche.component';
import { AttrezzatureDaOfficinaComponent } from './page/prodotti/attrezzature-da-officina/attrezzature-da-officina.component';
import { AccessoriPerAgricolturaComponent } from './page/prodotti/accessori-per-agricoltura/accessori-per-agricoltura.component';
import { FooterComponent } from './component/footer/footer.component';
import { DialogImgComponent } from './component/dialog-img/dialog-img.component';
import { ProvaComponent } from './prova/prova.component';
import { LightboxModule } from 'ngx-lightbox';
import { Errore404Component } from './page/errore404/errore404.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TopbarComponent,
    HomepageComponent,
    ChiSiamoComponent,
    ContattiComponent,
    ServiziComponent,
    ProgettazioneComponent,
    AssemblaggiComponent,
    RiparazioniComponent,
    VenditaComponent,
    MillerDpiComponent,
    CentraliOleodinamicheComponent,
    ComponentiComponent,
    RaccorderiaEAccessoriComponent,
    PneumaticaComponent,
    TubiFlessibiliComponent,
    CilindriOleodinamiciComponent,
    LavorazioniMeccanicheComponent,
    AttrezzatureDaOfficinaComponent,
    AccessoriPerAgricolturaComponent,
    FooterComponent,
    DialogImgComponent,
    ProvaComponent,
    Errore404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
