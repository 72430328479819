<section class="container text-center mt-5">
    <img class="img-fluid w-250" src="/assets/img/siras-oleodinamica-logo.png">
</section>
<div class="m-base pb-5">
<div class="bg-white radius-min mt-5  bordo-3d ">
<div class="row text-center pt-2" style="justify-content: center;">
  <div class="col-lg-4">
    <p class="font-siras text-left px-2 mr-3 text-white bg-siras titolo text-uppercase py-2 pl-4">Prodotti</p>
    <ul class="text-left lista">
        <li><a [routerLink]="'/prodotti/centrali-oleodinamiche'" class="lead text-siras">Centrali oleodinamiche</a></li>
        <li><a [routerLink]="'/prodotti/componenti'" class="lead text-siras">Componenti</a></li>
        <li><a [routerLink]="'/prodotti/raccorderia-e-accessori'" class="lead text-siras">Raccorderia e accessori</a></li>
        <li><a [routerLink]="'/prodotti/pneumatica'" class="lead text-siras">Pneumatica</a></li>
        <li><a [routerLink]="'/prodotti/tubi-flessibili'" class="lead text-siras">Tubi flessibili</a></li>
        <li><a [routerLink]="'/prodotti/cilindri-oleodinamici'" class="lead text-siras">Cilindri oleodinamici</a></li>
        <li><a [routerLink]="'/prodotti/lavorazioni-meccaniche'" class="lead text-siras">Lavorazioni meccaniche</a></li>
        <li><a [routerLink]="'/prodotti/attrezzature-da-officina'" class="lead text-siras">Attrezzatura da officina</a></li>
        <li><a [routerLink]="'/prodotti/accessori-per-agricolture'" class="lead text-siras">Accessori per agricoltura</a></li>


    </ul>
  </div>
  <div class="col-lg-8 mb-3" style="    text-align: -webkit-center;">
    <p class="font-siras text-left px-2 ml-3 text-white bg-siras titolo  py-2 pr-4">Servizi</p>
    <div class="col-lg-12 base mx-3 ">
        <a [routerLink]="'/progettazione'">
        <p class="text-siras titolo text-uppercase ">Progettazione</p>
       <img src="\assets\img\progettazione-siras-oleodinamica-1.jpg" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                mouse
                </span></div>
          </div>
          </a>
    </div>
    <div class="col-lg-12 base mx-3">
        <a [routerLink]="'/assemblaggi'">
        <p class="text-siras titolo text-uppercase">Assemblaggi</p>
       <img src="\assets\img\assemblaggi-siras-oleodinamica-1.jpg" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                settings
                </span></div>
          </div>
        </a>
    </div>
    <div class="col-lg-12 base mx-2">
        <a [routerLink]="'/riparazioni'">
        <p class="text-siras titolo text-uppercase">Riparazioni</p>
        <img src="\assets\img\riparazioni-siras-oleodinamica-1.jpg" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                build
                </span></div>
          </div>
        </a>
    </div>
    <div class="col-lg-12 base mx-2">
        <a [routerLink]="'/vendita'">
        <p class="text-siras titolo text-uppercase">Vendita</p>
        <img src="\assets\img\vendita-siras-oleodinamica-1.jpg" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                shopping_basket
                </span></div>
          </div>
        </a>
    </div>
    <!-- <div class="col-lg-12 base mx-2">
        <a [routerLink]="'/miller-dpi'">
        <p class="text-siras titolo text-uppercase">Miller DPI</p>
        <img src="\assets\img\miller-dpi-siras-oleodinamica-1.png" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                engineering
                </span></div>
          </div>
        </a>
    </div> -->

  </div>
</div>

<div class="bg-siras row m-0">
    <div class="col-lg-6 mt-5">
    <img class="img-fluid w-20 p-3" src="/assets/img/siras-oleodinamica-logo-2.png">
    </div>
    <div class="col-lg-6">
        <p class="lead text-white text-right p-3" style="font-size: 17px;">
            Siras S.a.S - S.S. 35 Milano-Genova - Via Turati, 16<br>
San Martino Siccomario (PV) P.IVA: 00211280185<br>
<a href="https://www.iubenda.com/privacy-policy/39661139" target="_blank" class=" text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<a href="https://www.iubenda.com/privacy-policy/39661139/cookie-policy"  target="_blank" class="text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe m-4" title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<a href="https://www.blu-system.com/" class="text-white" target="_blank">Powered by Blu System</a>
        </p>
    </div>
</div>
</div>
</div>




