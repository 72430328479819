import { Component, OnInit } from '@angular/core';
import { Lightbox, } from 'ngx-lightbox';

@Component({
  selector: 'app-lavorazioni-meccaniche',
  templateUrl: './lavorazioni-meccaniche.component.html',
  styleUrls: ['./lavorazioni-meccaniche.component.css']
})
export class LavorazioniMeccanicheComponent implements OnInit {
  album:any = [];
  constructor(private _lightbox: Lightbox) { 
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/componenti-per-cilindri-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/componenti-per-cilindri-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/barre-cromate-tubi-per-cilindri-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/barre-cromate-tubi-per-cilindri-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/manifold-speciali-circuistica-integrata-siras-oleodinamica-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/manifold-speciali-circuistica-integrata-siras-oleodinamica.jpg'})
    this.album.push({'src':'https://www.sirasoleodinamica.it/assets/img/particolari-meccanici-a-disegno-siras-oleodinamica-2-big.jpg','':'','thumb':'https://www.sirasoleodinamica.it/assets/img/particolari-meccanici-a-disegno-siras-oleodinamica-2.jpg'})
  }

  ngOnInit(): void {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

}
