import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/doc/siras-oleodinamica-servizi-e-prodotti.pdf');
    link.setAttribute('download', `assets/doc/siras-oleodinamica-servizi-e-prodotti.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

}

}
